import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/all_products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/all_products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="content">
              <div className="gallery-page">
                <h1 className="gallery-page__title">
                  Tutti i prodotti PIZ BUIN<sup>®</sup>
                </h1>
                <div className="clearfix">
                  <div className="gallery-page__filter hidden">
                    <div className="arrow">
                      <br />
                    </div>
                    <form method id="fmFilter">
                      <select
                        name="filter"
                        className="gallery-page__filter__select"
                      >
                        <optgroup>
                          <option value="0">Filtra per</option>
                          <option value="1,2,6,9,12,13" />
                          <option value="12,18,19">Whatevs IT</option>
                          <option value="1,2,4" />
                          <option value="1" />
                          <option value="13" />
                        </optgroup>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="innerContent clearfix gallery-page__list">
                  <div
                    style={{
                      margin: "40px"
                    }}
                  >
                    <p>
                      Da oltre 70 anni, PIZ BUIN® dona agli amanti del sole
                      prodotti solari che aiutano ad ottenere il perfetto
                      equilibrio tra una bella abbronzatura e la protezione
                      solare necessaria. Verifica sempre che i prodotti PIZ
                      BUIN® che acquisti abbiano le stesse caratteristiche di
                      quelli indicati in questo sito. Controlla che le
                      etichette, la forma e il colore della confezione siano
                      conformi a quelli qui rappresentati. Se hai dubbi
                      sull’autenticità dei prodotti che hai comprato, contatta
                      pure il Consumer Care Kenvue Italia: 
                      <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pMAAQ" target="_blank">email</a> numero verde: 00800 555 22 000
                    </p>
                  </div>
                  <a
                    href="/it/prodotti/hydro-infusion/#sun-gel-cream-face"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM FACE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/hydro-infusion/#sun-gel-cream"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-sun-gel-cream-50spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/active-protect/#actect"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-active-protect-sun-lotion-100ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Active &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/moisturising/#latte-solare"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LATTE SOLARE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/moisturising/#stick-solare-per-labbra"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          STICK SOLARE PER LABBRA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/moisturising/#spray-solare-idratante"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/8bf310057998eb9035da232074f98217_f33.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLARE IDRATANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/tan_protect/#olio-spray-solare-acceleratore-dell-abbronzatura"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/597e55fa85eadc497dda9761c9c29e48_f305.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          OLIO SPRAY SOLARE ACCELERATORE DELL’ABBRONZATURA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/tan_protect/#lozione-solare-intensificatore-dell-abbronzatura"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/cdb96eb7dd5017e10ae2166b22fc0b37_f28.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOZIONE SOLARE INTENSIFICATORE DELL’ABBRONZATURA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/tan_protect/#spray-solare-intensificatore-dell-abbronzatura"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/a47bbd731c60db7dd7e883e6bec26662_f564.png" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLARE INTENSIFICATORE DELL’ABBRONZATURA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/instantglow/#spray-illuminatore-della-pelle"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-instant-glow-skin-illuminating-sun-spray-30spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY ILLUMINATORE DELLA PELLE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/instantglow/#lozione-solare-illuminatore-della-pelle"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/358d047f70bae5a3276b9144733fd4fb_f404.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOZIONE SOLARE ILLUMINATORE DELLA PELLE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/allergy/#latte-solare"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LATTE SOLARE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/allergy/#spray-piel-sensible"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-spray-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY PELLE SENSIBILE AL SOLE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/allergy/#crema-viso"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-face-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          CREMA VISO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/mountain/#crema-solare"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/6dbc9c111c0b9ae9db8aaa8f89873294_f27.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          CREMA SOLARE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/mountain/#crema-solare-stick-labbra"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/mountain-lipstick-new-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          CREMA SOLARE + STICK LABBRA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/mountain/#stick-labbra"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/11cfa6eaadc71b321ef378438ce0de09_f26.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          STICK LABBRA
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/mountain/#glacier-cream"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/glacier-cream-gallery.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          GLACIER CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/aftersun/#latte-idratante-lenitivo-e-rinfrescante"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-soothing-cooling-moisturising-lotion-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LATTE IDRATANTE LENITIVO E RINFRESCANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/aftersun/#loci-n-hidratante-intensificadora-del-bronceado"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          Latte Doposole Intensificatore di Abbronzatura
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/it/prodotti/aftersun/#instant-mist-spray"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          After Sun Spray Sollievo Immediato
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $(".heroImage > img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$(".heroImage").empty();\n})\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
